import { SxProps, Theme } from "@mui/material/styles"
import * as React from "react"
import {
  StyledTabHeader,
  StyledTable,
  StyledTableCell,
  StyledTableCellText,
  StyledTableContainer,
  StyledTableBodyContainer,
  StyledTableHeaderContainer,
  StyledTableHeading,
  StyledTableRow,
  StyledTableTabs,
  StyledTableTabsContainer
} from "./styles"

interface TableProps {
  columnsWidths: string[][]
  data: unknown[][]
  headings: string[]
  renderRow: (rowData: any, index: number, sx: SxProps) => React.ReactNode
  tabs: string[]
}

function Table({ columnsWidths, data, headings, renderRow, tabs }: TableProps) {
  const ref = React.useRef<HTMLTableElement>(null)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const tabData = data[activeTabIndex]

  const onTabClick =
    (index: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e && e.preventDefault()

      setActiveTabIndex(index)
    }

  return (
    <StyledTableContainer>
      <StyledTableTabsContainer>
        <StyledTableTabs>
          {tabs.map((tab, i) => {
            const isActive = i === activeTabIndex
            const key = `table-tab-${i}`
            const sx = () =>
              isActive
                ? {
                    borderBottom: "1px solid #000",
                    color: "#000"
                  }
                : {}

            return (
              <StyledTabHeader key={key} onClick={onTabClick(i)} sx={sx}>
                {tab}
              </StyledTabHeader>
            )
          })}
        </StyledTableTabs>
      </StyledTableTabsContainer>
      <StyledTable as="table" ref={ref}>
        <StyledTableHeaderContainer as="thead">
          <StyledTableRow>
            {headings.map((heading, i) => {
              const key = `table-heading-${i}`
              const isSticky = i === 0
              const width = columnsWidths[i - 1]

              const sx = (theme: Theme) =>
                isSticky
                  ? {
                      backgroundColor: "#fff", // Makes sure that heading is visible when scrolling
                      justifyContent: "flex-start",
                      left: 0,
                      position: "sticky",

                      [theme.breakpoints.up("md")]: {
                        flex: 1
                      }
                    }
                  : {
                      width
                    }

              return (
                <StyledTableHeading key={key} sx={sx}>
                  {heading}
                </StyledTableHeading>
              )
            })}
          </StyledTableRow>
        </StyledTableHeaderContainer>
        <StyledTableBodyContainer as="tbody">
          {tabData.map((rowData, i) => {
            const key = `table-row-${i}`

            const sx: SxProps = {
              marginBottom: "16px",

              "& td:first-of-type": {
                backgroundColor: "#fff", // Makes sure that content is visible when scrolling
                justifyContent: "flex-start",
                left: 0,
                position: "sticky"
              }
            }

            return (
              <React.Fragment key={key}>
                {renderRow(rowData, i, sx)}
              </React.Fragment>
            )
          })}
        </StyledTableBodyContainer>
      </StyledTable>
    </StyledTableContainer>
  )
}

function Row({ children, sx }: { children: React.ReactNode; sx: SxProps }) {
  return <StyledTableRow sx={sx}>{children}</StyledTableRow>
}

function Cell({
  children,
  sx = {}
}: {
  children: React.ReactNode
  sx?: SxProps<Theme>
}) {
  return <StyledTableCell sx={sx}>{children}</StyledTableCell>
}

Cell.defaultProps = {
  sx: {}
}

function CellText({ children }: { children: React.ReactNode }) {
  return <StyledTableCellText>{children}</StyledTableCellText>
}

Table.Cell = Cell
Table.CellText = CellText
Table.Row = Row

export default Table
