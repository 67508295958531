import * as React from "react"
import { HeroCollection } from "../../types"
import Hero from "../Hero/Hero"
import { StyledFeaturedCollectionsContainer } from "./styles"

interface FeaturedCollectionsProps {
  collections: HeroCollection[]
}

function FeaturedCollections({ collections }: FeaturedCollectionsProps) {
  const count = collections.length

  return (
    <StyledFeaturedCollectionsContainer>
      {collections.map((collection, i) => {
        const key = `featured-collections-${i}`

        return (
          <Hero
            key={key}
            collection={collection}
            count={count}
            index={i}
            sx={{ gridColumns: "1 / -1", scrollSnapAlign: "start" }}
          />
        )
      })}
    </StyledFeaturedCollectionsContainer>
  )
}

export default FeaturedCollections
