import { styled } from "@mui/material/styles"

export const StyledItemContainer = styled("div")(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
}))

export const StyledItemImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  minHeight: "197px",
  width: "197px",

  [theme.breakpoints.up("md")]: {
    minHeight: "329px",
    width: "329px"
  }
}))

export const StyledItemRow = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "11px 0 0",
  width: "100%",

  "& &": {
    margin: "0"
  }
}))

export const StyledItemTitle = styled("h3")(({ theme }) => ({
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  margin: 0,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))

export const StyledItemPriceBTC = styled("h3")(({ theme }) => ({
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  margin: 0,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))

export const StyledItemPriceUSD = styled("h3")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  margin: 0,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))
