import * as React from "react"
import BackgroundColor from "../../utils/background-color"
import { StyledPage } from "./styles"

interface PageProps {
  backgroundColor?: string
  children: React.ReactNode
}

function Page({ backgroundColor, children }: PageProps) {
  return (
    <>
      <BackgroundColor color={backgroundColor as string} />
      <StyledPage>{children}</StyledPage>
    </>
  )
}

Page.defaultProps = {
  backgroundColor: "#fff"
}

export default Page
