import { Theme } from "@mui/material"
import * as React from "react"
import Video from "../../../../components/Video"
import {
  StyledBackgroundContainer,
  StyledBackgroundLeft,
  StyledBackgroundCenter,
  StyledBackgroundRight,
  StyledButton,
  StyledHero,
  StyledIcon,
  StyledIconsContainer,
  StyledLogo,
  StyledLogoContainer,
  StyledBottomText,
  StyledTopText
} from "./styles"

interface ExternalLinkProps {
  children: React.ReactNode
  href: string
}

function ExternalLink({ children, href }: ExternalLinkProps) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

function Hero() {
  return (
    <StyledHero>
      <StyledBackgroundContainer>
        <StyledLogoContainer>
          <StyledLogo src="/etcher-pink.svg" />

          <StyledButton
            className="vrlps-trigger"
            sx={(theme: Theme) => ({
              display: "none",

              [theme.breakpoints.up("md")]: {
                display: "flex"
              }
            })}
          >
            Request Access
          </StyledButton>
        </StyledLogoContainer>

        <StyledBackgroundLeft>
          <Video autoPlay>
            <source src="/media/mystery/promo.webm" type="video/webm" />
            <source src="/media/mystery/promo.mp4" type="video/mp4" />
          </Video>
        </StyledBackgroundLeft>
        <StyledBackgroundCenter>
          <Video autoPlay>
            <source src="/media/mystery/promo.webm" type="video/webm" />
            <source src="/media/mystery/promo.mp4" type="video/mp4" />
          </Video>
        </StyledBackgroundCenter>
        <StyledBackgroundRight>
          <Video autoPlay>
            <source src="/media/mystery/promo.webm" type="video/webm" />
            <source src="/media/mystery/promo.mp4" type="video/mp4" />
          </Video>
        </StyledBackgroundRight>
      </StyledBackgroundContainer>
      <StyledTopText>
        A NEW ERA FOR CREATORS & COLLECTORS ON BITCOIN.
      </StyledTopText>
      <StyledBottomText>BEAUTY MEETS ORDINALS.</StyledBottomText>
      <StyledButton
        className="vrlps-trigger"
        sx={(theme: Theme) => ({
          [theme.breakpoints.up("md")]: {
            display: "none"
          }
        })}
      >
        Request Access
      </StyledButton>
      <StyledIconsContainer>
        <ExternalLink href="https://www.twitter.com/etcherBTC">
          <StyledIcon src="/icons/twitter.svg" />
        </ExternalLink>
      </StyledIconsContainer>
    </StyledHero>
  )
}

export default Hero

/* We will add these later
<ExternalLink href="#">
  <StyledIcon src="/icons/discord.svg" />
</ExternalLink>
<ExternalLink href="#">
  <StyledIcon src="/icons/instagram.svg" />
</ExternalLink>
*/
