import * as React from "react"
import { createBrowserRouter } from "react-router-dom"
import data from "./data"
// import ViewFoo from "./views/foo/Foo"
import Layout from "./layouts/app/Layout"
import ViewCollection from "./views/collection/Collection"
import ViewHomepage from "./views/homepage/Homepage"
import ViewMystery from "./views/mystery/Mystery"
import ViewOrdinal from "./views/ordinal/Ordinal"
import ViewStats from "./views/stats/Stats"

/**
 * Route options. Wanted to put this in the `Route` function but
 * React would not allow it due to how it handles hooks :|
 */
export interface RouteOptions {
  fullLogo?: boolean
  protected: boolean
}

function Route(
  View: React.ElementType,
  props: any = {},
  options: RouteOptions = {
    fullLogo: false,
    protected: false
  }
) {
  // Has to be `any` since props are dynamic
  const layoutProps: any = {
    ...props,
    ...options
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Layout {...layoutProps}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <View {...props} />
    </Layout>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: Route(ViewMystery, data.mystery, {
      protected: false
    })
  },
  {
    path: "/collections/:slug",
    element: Route(ViewCollection, data.collection, { protected: true })
  },
  {
    path: "/homepage",
    element: Route(ViewHomepage, data.homepage, {
      fullLogo: true,
      protected: true
    })
  },
  {
    path: "/ordinals/:slug",
    element: Route(ViewOrdinal, data.ordinal, { protected: true })
  },
  {
    path: "/stats",
    element: Route(ViewStats, data.stats, { protected: true })
  }
])

export default router
