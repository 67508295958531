import * as React from "react"
import HorizontalGrid from "../../../../components/HorizontalGrid/HorizontalGrid"
import Section from "../../components/Section/Section"
import { SectionClubsProps } from "../../types"
import { Item } from "../Selects/Selects"

function SectionClubs({ items, title }: SectionClubsProps) {
  return (
    <Section title={title}>
      <HorizontalGrid
        columnWidth={["197px", "329px"]}
        gap={["20px", "50px"]}
        rowNum={[1, 1]}
      >
        {items.map((props, i) => {
          const key = `selects-item-${i}`

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Item key={key} {...props} />
        })}
      </HorizontalGrid>
    </Section>
  )
}

export default SectionClubs
