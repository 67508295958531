import { styled } from "@mui/material/styles"

export const StyledContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column dense",
  marginRight: "-20px",
  overflowX: "scroll",
  overflowY: "hidden",
  position: "relative",
  scrollSnapType: "x mandatory",
  width: "unset",
  whiteSpace: "nowrap",

  // Prevents scroll bar from showing, but still allows scrolling
  "&::-webkit-scrollbar": {
    display: "none"
  },

  [theme.breakpoints.up("md")]: {
    marginRight: "-40px"
  }
}))

export const foo = "bar"
