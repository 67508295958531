import * as React from "react"
import { CollectionSummaryBoxProps } from "../../types"
import {
  StyledBox,
  StyledDetailBox,
  StyledIcon,
  StyledLink,
  StyledReadMoreText,
  StyledSummaryText
} from "./styles"

function Icon({ icon, url }: { icon: string; url: string }) {
  const imageUrl = `/media/collection/img/${icon}`

  return (
    <StyledLink href={url}>
      <StyledIcon src={imageUrl} />
    </StyledLink>
  )
}

function SummaryBox({ links, summary }: CollectionSummaryBoxProps) {
  return (
    <StyledDetailBox
      sx={(theme) => ({
        zIndex: 1,

        [theme.breakpoints.up("md")]: {
          bottom: "unset",
          left: "60px",
          position: "absolute",
          top: "550px",
          width: "561px"
        }
      })}
    >
      <StyledBox
        sx={(theme) => ({
          alignItems: "flex-start",
          borderTop: "none",
          order: 1,
          padding: "16px 20px",

          [theme.breakpoints.up("md")]: {
            borderBottom: "1px solid #000",
            order: 2
          }
        })}
      >
        <StyledSummaryText>{summary}</StyledSummaryText>
        <StyledReadMoreText>Read More</StyledReadMoreText>
      </StyledBox>
      <StyledBox
        sx={(theme) => ({
          borderBottom: "1px solid #000",
          flexDirection: "row",
          order: 2,
          padding: "21px 20px",

          [theme.breakpoints.up("md")]: {
            order: 1
          }
        })}
      >
        <Icon icon="website.svg" url={links.website} />
        <Icon icon="discord.svg" url={links.discord} />
        <Icon icon="instagram.svg" url={links.instagram} />
        <Icon icon="twitter.svg" url={links.twitter} />
      </StyledBox>
    </StyledDetailBox>
  )
}

export default SummaryBox
