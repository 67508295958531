import * as React from "react"
import { SectionProps } from "../../types"
import Dropdown from "./Dropdown"
import { StyledSection, StyledTitle, StyledTitleContainer } from "./styles"

function Section({ children, dropdown, title }: SectionProps) {
  return (
    <StyledSection>
      <StyledTitleContainer>
        <StyledTitle>{title}</StyledTitle>
        {dropdown && <Dropdown dropdown={dropdown} />}
      </StyledTitleContainer>
      {children}
    </StyledSection>
  )
}

Section.defaultProps = {
  dropdown: undefined
}

export default Section
