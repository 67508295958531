/* eslint-disable */

// See: https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser

const mbrowser = function () {
  this.spec_string = navigator.userAgent
  this.name = this.get_name()
  this.version = this.get_version()
}

mbrowser.prototype.get_name = function () {
  const { spec_string } = this

  let matches =
    spec_string.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || []

  // Work with the matches.
  matches = matches[2]
    ? [matches[1], matches[2]]
    : [navigator.appName, navigator.appVersion, "-?"]

  // Trident.
  if (/trident/i.test(matches[1])) {
    var temp = /\brv[ :]+(\d+)/g.exec(spec_string) || []
    return "IE"
  }

  // Chrome.
  if (matches[1] === "Chrome") {
    var temp = spec_string.match(/\bOPR|Edge\/(\d+)/)
    if (temp != null) {
      return "Opera"
    }
  }

  if ((temp = spec_string.match(/version\/(\d+)/i)) != null) {
    matches.splice(1, 1, temp[1])
  }

  const name = matches[0]

  return name
}

mbrowser.prototype.get_version = function () {
  const { spec_string } = this

  let matches =
    spec_string.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || []

  // Work with the matches.
  matches = matches[2]
    ? [matches[1], matches[2]]
    : [navigator.appName, navigator.appVersion, "-?"]

  // Trident.
  if (/trident/i.test(matches[1])) {
    var temp = /\brv[ :]+(\d+)/g.exec(spec_string) || []
    var version = temp[1] || ""
    return version
  }

  // Chrome.
  if (matches[1] === "Chrome") {
    var temp = spec_string.match(/\bOPR|Edge\/(\d+)/)
    var version = temp[1]
    if (temp != null) {
      return version
    }
  }

  if ((temp = spec_string.match(/version\/(\d+)/i)) != null) {
    matches.splice(1, 1, temp[1])
  }

  var version = matches[1]

  return version
}

function init() {
  if (typeof window === 'undefined')
    return { name: 'node', version: 'whatever' }

  const browser = new mbrowser()

  return browser
}

export default init()
