import * as React from "react"
import HorizontalGrid from "../../../../components/HorizontalGrid/HorizontalGrid"
import backgroundUrl from "../../../../utils/background-url"
import Section from "../../components/Section/Section"
import { SectionJustLaunchedProps, SectionJustLaunchedItem } from "../../types"
import {
  StyledItemContainer,
  StyledItemImage,
  StyledItemPriceBTC,
  StyledItemRow,
  StyledItemTitle
} from "./styles"

export function Item({ imageUrl, priceBTC, title }: SectionJustLaunchedItem) {
  return (
    <StyledItemContainer>
      <StyledItemImage sx={{ backgroundImage: backgroundUrl(imageUrl) }} />
      <StyledItemRow>
        <StyledItemTitle>{title}</StyledItemTitle>
        <StyledItemPriceBTC>{priceBTC}</StyledItemPriceBTC>
      </StyledItemRow>
    </StyledItemContainer>
  )
}

function SectionJustLaunched({ items, title }: SectionJustLaunchedProps) {
  return (
    <Section title={title}>
      <HorizontalGrid
        columnWidth={["129px", "231px"]}
        gap={["20px", "50px"]}
        rowNum={[2, 2]}
      >
        {items.map((props, i) => {
          const key = `selects-item-${i}`

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Item key={key} {...props} />
        })}
      </HorizontalGrid>
    </Section>
  )
}

export default SectionJustLaunched
