import { styled } from "@mui/material/styles"

export const StyledFeaturedCollectionsContainer = styled("div")(
  ({ theme }) => ({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "530px",
    overflowX: "scroll",
    overflowY: "hidden",
    position: "relative",
    scrollSnapType: "x mandatory",
    width: "unset",
    whiteSpace: "nowrap",

    // Prevents scroll bar from showing, but still allows scrolling
    "&::-webkit-scrollbar": {
      display: "none"
    },

    [theme.breakpoints.up("md")]: {
      gridAutoColumns: "100vw"
    }
  })
)

export const StyledFeaturedCollections = styled("div")(() => ({}))
