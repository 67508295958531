import { styled } from "@mui/material/styles"

export const StyledGridContainer = styled("div")(({ theme }) => ({
  borderTop: "1px solid #000",
  display: "flex",
  flexDirection: "column",
  margin: "0 -20px",
  padding: "20px",

  [theme.breakpoints.up("md")]: {
    margin: "0 -40px",
    padding: "40px"
  }
}))

export const StyledGridHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledGridTitle = styled("h2")(({ theme }) => ({
  color: "#000",
  fontFamily: "ABCFavorit, sans-serif",
  fontSize: "24px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "64px"
  }
}))

export const StyledGridBody = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(2, 1fr)",
  marginTop: "20px",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)"
  }
}))

export const StyledGridItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "165px",

  [theme.breakpoints.up("md")]: {
    width: "415px"
  }
}))

export const StyledGridItemRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledGridImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "196px",
  marginBottom: "10px",
  width: "165px",

  [theme.breakpoints.up("md")]: {
    height: "494px",
    marginBottom: "15px",
    width: "415px"
  }
}))

export const StyledGridItemText = styled("span")(({ theme }) => ({
  color: "#000",
  fontFamily: "NBIBold",
  fontSize: "12px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "120%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  }
}))

export const StyledGridItemSubText = styled("span")(({ theme }) => ({
  color: "#666",
  fontFamily: "NBIBold",
  fontSize: "12px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "120%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  }
}))
