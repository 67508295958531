import * as React from "react"
import Page from "../../components/Page/Page"
import Table from "./components/Table/Table"
import { StyledStatsPageContainer, StyledStatsPageHeader } from "./styles"
import { StatsProps } from "./types"

function StatsPage({
  tabs,
  headings,
  columnsColored,
  columnsSuffix,
  columnsWidths,
  data
}: StatsProps) {
  return (
    <Page>
      <StyledStatsPageContainer>
        <StyledStatsPageHeader>Stats</StyledStatsPageHeader>
        <Table
          tabs={tabs}
          headings={headings}
          columnsColored={columnsColored}
          columnsSuffix={columnsSuffix}
          columnsWidths={columnsWidths}
          data={data}
        />
      </StyledStatsPageContainer>
    </Page>
  )
}

export default StatsPage
