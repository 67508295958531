import { styled } from "@mui/material/styles"
import hexToFilter from "../../../../utils/hex-to-filter"

export const StyledBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 -20px",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    margin: "0 -40px"
  }
}))

export const StyledFeaturedImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "50vh",
  width: "100vw",

  [theme.breakpoints.up("md")]: {
    height: "782px",
    width: "782px"
  }
}))

export const StyledDetailsSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",

  [theme.breakpoints.up("md")]: {
    marginLeft: "80px",
    marginTop: "80px",
    padding: 0,
    width: "413px"
  }
}))

export const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledCollectionTitle = styled("div")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledOrdinalTitle = styled("div")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "ABCFavorit",
  fontSize: "24px",
  fontWeight: 700,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  }
}))

export const StyledPriceSection = styled("div")(({ theme }) => ({
  borderBottom: "1px solid #aaa",
  borderTop: "1px solid #aaa",
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
  padding: "20px 0",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledLabel = styled("div")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledPrice = styled("div")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "18px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledSubPrice = styled("div")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "18px",
  fontWeight: 500,
  marginLeft: "6px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledSatBox = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#ffb168",
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  justifyContent: "center",
  textTransform: "uppercase",
  width: "88px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledBuyNowButton = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#000",
  color: "#fff",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "18px",
  fontWeight: 500,
  height: "65px",
  justifyContent: "center",
  marginTop: "20px",
  textTransform: "uppercase",
  width: "211px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledBuyNowIcon = styled("img")(({ theme }) => ({
  display: "flex",
  height: "21px",
  marginLeft: "6px",
  width: "21px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledOtherListingsSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px 0",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledAnchor = styled("a")(() => ({
  display: "flex"
}))

export const StyledOtherListingIcon = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  filter: hexToFilter("#666"),
  marginRight: "15px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledAccordianSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledAccordianContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px 0",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledAccordianIcon = styled("img")(({ theme }) => ({
  display: "flex",
  filter: hexToFilter("#666"),
  height: "10px",
  marginLeft: "8px",
  width: "20px",

  [theme.breakpoints.up("md")]: {
    height: "14px",
    width: "29px"
  }
}))

export const StyledAccordianTitle = styled("span")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "18px",
  fontWeight: 500,
  letterSpacing: "0.54px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitList = styled("ul")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitItem = styled("li")(({ theme }) => ({
  borderBottom: "1px solid #666",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 0",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitLabel = styled("span")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.7px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitValueContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "160px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitValue = styled("span")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.7px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTraitRarity = styled("span")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  height: "21px",
  padding: "0 4px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledSatRarity = styled("span")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "14px",
  fontWeight: 500,
  height: "38px",
  padding: "10px 12px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))
