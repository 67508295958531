import * as React from "react"

function getWindowDimensions() {
  const { innerWidth, innerHeight } = window
  return {
    windowHeight: innerHeight,
    windowWidth: innerWidth
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  )

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
