import { styled } from "@mui/material/styles"

export const StyledStatsPageContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "80px"
}))

export const StyledStatsPageHeader = styled("h1")(() => ({
  color: "#000",
  display: "flex",
  fontFamily: "ABCFavorit, sans-serif",
  fontSize: "64px",
  fontWeight: 500,
  letterSpacing: "-2.7px",
  textTransform: "uppercase"
}))
