import { styled } from "@mui/material/styles"

export const StyledHeroGradientTop = styled("div")(({ theme }) => ({
  backgroundImage: "url(/media/homepage/img/gradient-top2.png)",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "133px",
  left: 0,
  opacity: 0.7,
  padding: "0 20px",
  position: "absolute", // Or should this scroll up too?
  top: 0,
  width: "100%",
  zIndex: 2,

  [theme.breakpoints.up("md")]: {
    padding: "0 40px"
  }
}))

export const StyledHamburger = styled("img")(() => ({
  display: "flex",
  height: "15px",
  width: "33px"
}))

export const StyledLogo = styled("img")(() => ({
  display: "flex",
  // filter: "invert(1)",
  height: "32px",
  width: "40px"
}))

export const StyledSearchWrapper = styled("div")(() => ({
  alignItems: "center",
  display: "none", // Don't need it right now
  justifyContent: "center"
}))

export const StyledLinks = styled("div")(() => ({
  display: "flex"
}))

export const StyledLink = styled("a")(() => ({
  color: "#fff",
  display: "flex",
  fontSize: "16px",
  letterSpacing: "0.03em",
  fontWeight: 700,
  margin: "0 8px",
  padding: "4px 8px",
  textDecoration: "none",
  textTransform: "uppercase"
}))
