import { SxProps, Theme } from "@mui/material/styles"
import * as React from "react"
import backgroundUrl from "../../../../utils/background-url"
import { HeroCollection } from "../../types"
import {
  StyledContent,
  StyledHero,
  StyledItem01,
  StyledItem02,
  StyledItem03,
  StyledItem04,
  StyledItemPrice,
  StyledItemTitle,
  StyledSquare,
  StyledSquares,
  StyledItemTextContainer
} from "./styles"

interface HeroProps {
  collection: HeroCollection
  count: number // How many squares to render
  index: number // Index of the collection
  sx: SxProps<Theme>
}

function Hero({ collection, count, index, sx = {} }: HeroProps) {
  return (
    <StyledHero sx={{ ...sx }}>
      <StyledContent>
        <StyledItem01
          sx={{ backgroundImage: backgroundUrl(collection.item01.imageUrl) }}
        >
          <StyledItemTextContainer>
            <StyledItemTitle>{collection.item01.title}</StyledItemTitle>
            <StyledItemPrice>{collection.item01.price}</StyledItemPrice>
          </StyledItemTextContainer>
        </StyledItem01>
        <StyledItem02
          sx={{ backgroundImage: backgroundUrl(collection.item02.imageUrl) }}
        >
          <StyledItemTextContainer>
            <StyledItemTitle>{collection.item02.title}</StyledItemTitle>
            <StyledItemPrice>{collection.item02.price}</StyledItemPrice>
          </StyledItemTextContainer>
        </StyledItem02>
        <StyledItem03
          sx={{ backgroundImage: backgroundUrl(collection.item03.imageUrl) }}
        >
          <StyledItemTextContainer>
            <StyledItemTitle>{collection.item03.title}</StyledItemTitle>
            <StyledItemPrice>{collection.item03.price}</StyledItemPrice>
          </StyledItemTextContainer>
        </StyledItem03>
        <StyledItem04
          sx={{ backgroundImage: backgroundUrl(collection.item04.imageUrl) }}
        >
          <StyledItemTextContainer>
            <StyledItemTitle>{collection.item04.title}</StyledItemTitle>
            <StyledItemPrice>{collection.item04.price}</StyledItemPrice>
          </StyledItemTextContainer>
        </StyledItem04>

        <StyledSquares>
          {Array(count)
            .fill("")
            .map((c, i) => {
              const key = `square-${i}`

              const squareSx = {
                backgroundColor: i === index ? "#000" : "transparent"
              }

              return <StyledSquare key={key} sx={squareSx} />
            })}
        </StyledSquares>
      </StyledContent>
    </StyledHero>
  )
}

export default Hero
