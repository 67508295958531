import { styled } from "@mui/material/styles"
import hexToFilter from "../../../../utils/hex-to-filter"

export const StyledDetailBox = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledBox = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#fff",
  borderLeft: "1px solid #000",
  borderRight: "1px solid #000",
  borderTop: "1px solid #000",
  display: "flex",
  flexDirection: "column",
  padding: "11px 20px",
  width: "100%",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledCol = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "10px 0",
  width: "100%",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTitle = styled("h2")(({ theme }) => ({
  color: "#000",
  fontFamily: "ABCFavorit",
  fontSize: "40px",
  fontWeight: 500,
  letterSpacing: "-1.2px",
  lineHeight: "100%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "80px",
    lineHeight: "107px"
  }
}))

export const StyledStatLabel = styled("span")(({ theme }) => ({
  color: "#000",
  fontFamily: "NBIMono",
  fontSize: "12px",
  letterSpacing: "0.36px",
  marginBottom: "7px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  }
}))

export const StyledStatValue = styled("span")(({ theme }) => ({
  color: "#000",
  fontFamily: "NBIMedium",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "110%",
  letterSpacing: "0.6px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  }
}))

export const StyledStatSubValue = styled("span")(({ theme }) => ({
  color: "#666",
  fontFamily: "NBIMono",
  fontSize: "12px",
  marginLeft: "8px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  }
}))

export const StyledSummaryText = styled("p")(({ theme }) => ({
  color: "#000",
  fontFamily: "NBIRegular",
  fontSize: "16px",

  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  }
}))

export const StyledReadMoreText = styled("span")(({ theme }) => ({
  color: "#000",
  fontFamily: "NBIRegular",
  fontSize: "16px",
  marginTop: "6px",
  textDecoration: "underline",

  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  }
}))

export const StyledIcon = styled("img")(({ theme }) => ({
  display: "flex",
  filter: hexToFilter("#666"),
  marginRight: "22px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledLink = styled("a")(() => ({
  display: "flex"
}))
