import * as React from "react"
import Page from "../../components/Page/Page"
import { ViewProps } from "../types"
import Hero from "./components/Hero/Hero"

type MysteryPageProps = ViewProps

// eslint-disable-next-line no-empty-pattern
function MysteryPage({}: MysteryPageProps) {
  return (
    <Page backgroundColor="#000">
      {/* @ts-expect-error This is a component from a JS lib */}
      <form-widget
        mode="popup"
        ucid="iqOOE8sJoeeourANXmGXbZ6eSAs"
        component="customButton"
      />
      <Hero />
    </Page>
  )
}

export default MysteryPage
