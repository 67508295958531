import { styled } from "@mui/material/styles"

export const StyledHero = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "black",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  fontFamily: "ABCFavorit, sans-serif",
  height: "100vh",
  justifyContent: "center",
  marginBottom: "60px",
  position: "relative",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    height: "768px",
    marginBottom: "110px"
  }
}))

export const StyledLogo = styled("img")(({ theme }) => ({
  display: "flex",
  height: "95px",
  marginTop: "-84px",
  width: "373px",
  zIndex: 1,

  [theme.breakpoints.up("md")]: {
    height: "151px",
    marginTop: "0px",
    width: "593px"
  }
}))

export const StyledBackgroundContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "360px",
  marginLeft: "96px",
  marginTop: "0px",
  position: "relative",
  width: "600px",
  zIndex: 0,

  [theme.breakpoints.up("md")]: {
    height: "522px",
    left: "calc(50vw - 560px)",
    marginLeft: "14px",
    position: "absolute",
    top: "calc(50vh - 260px)",
    width: "993px"
  }
}))

export const StyledLogoContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  height: "100%",
  left: "-48px", // Must be 50% inverse as StyledBackgroundContainer marginLeft
  justifyContent: "center",
  position: "absolute",
  top: 0,
  width: "100%",

  [theme.breakpoints.up("md")]: {
    left: 0
  }
}))

export const StyledBackgroundLeft = styled("div")(({ theme }) => ({
  display: "flex",
  height: "181px",
  marginLeft: "-50px",
  marginRight: "30px",
  marginTop: "80px",
  transform: "rotate(-90deg)",
  width: "145px",

  [theme.breakpoints.up("md")]: {
    height: "241px",
    marginLeft: "0",
    marginRight: "-15px",
    marginTop: "190px",
    width: "301px"
  }
}))

export const StyledBackgroundCenter = styled("div")(({ theme }) => ({
  display: "flex",
  height: "314px",
  margin: "0 10px 0 0",
  width: "251px",

  [theme.breakpoints.up("md")]: {
    height: "522px",
    width: "418px"
  }
}))

export const StyledBackgroundRight = styled("div")(({ theme }) => ({
  display: "flex",
  height: "178px",
  marginLeft: "20px",
  transform: "rotate(-90deg)",
  width: "142px",

  [theme.breakpoints.up("md")]: {
    height: "296px",
    marginLeft: "32px",
    width: "237px"
  }
}))

export const StyledTopText = styled("div")(({ theme }) => ({
  color: "#fff",
  fontFamily: "ABCFavorit, sans-serif",
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "110%",
  position: "absolute",
  left: "0px",
  textTransform: "uppercase",
  top: "26px",
  width: "360px",

  "@media screen and (max-width: 375px)": {
    width: "324px"
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
    width: "616px"
  }
}))

export const StyledBottomText = styled("div")(({ theme }) => ({
  bottom: "80px",
  color: "#fff",
  fontSize: "24px",
  lineHeight: "110%",
  justifyContent: "flex-end",
  position: "absolute",
  right: "0px",
  textAlign: "right",
  textTransform: "uppercase",
  width: "330px",

  [theme.breakpoints.up("md")]: {
    bottom: "30px",
    fontSize: "32px",
    width: "430px"
  }
}))

export const StyledButton = styled("a")(({ theme }) => ({
  alignItems: "center",
  border: "2px solid #ff6000",
  color: "#fff",
  cursor: "pointer",
  display: "flex",
  fontFamily: "NBIBold, sans-serif",
  fontSize: "16px",
  height: "51px",
  justifyContent: "center",
  marginTop: "0px",
  textTransform: "uppercase",
  width: "251px",

  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: "-8px",
    top: "354px"
  }
}))

export const StyledIconsContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: "20px",
  right: "0px",

  [theme.breakpoints.up("md")]: {
    bottom: "unset",
    top: "20px"
  }
}))

export const StyledIcon = styled("img")(() => ({
  display: "flex",
  height: "24px",
  marginLeft: "16px",
  width: "24px"
}))
