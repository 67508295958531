import { styled } from "@mui/material/styles"

export const StyledTableSection = styled("div")(() => ({
  display: "flex",
  flexDirection: "column"
}))

export const StyledCellNumber = styled("span")(({ theme }) => ({
  color: "#000",
  display: "inline-block",
  marginRight: "20px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledCellValue = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center"
}))

export const StyledCellImage = styled("div")(() => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "60px",
  width: "60px"
}))

export const StyledBTCIcon = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "inline-block",
  height: "32px",
  marginLeft: "10px",
  width: "32px",

  [theme.breakpoints.up("md")]: {
    height: "42px",
    width: "42px"
  }
}))
