import * as React from "react"
import backgroundUrl from "../../../../utils/background-url"
import { CollectionGridItem } from "../../types"
import {
  StyledGridBody,
  StyledGridContainer,
  StyledGridHeader,
  StyledGridImage,
  StyledGridItem,
  StyledGridItemRow,
  StyledGridItemText,
  StyledGridItemSubText,
  StyledGridTitle
} from "./styles"

function Item({ imageUrl, priceBTC, priceUSD, title }: CollectionGridItem) {
  return (
    <StyledGridItem>
      <StyledGridImage sx={{ backgroundImage: backgroundUrl(imageUrl) }} />
      <StyledGridItemRow>
        <StyledGridItemText>{title}</StyledGridItemText>
      </StyledGridItemRow>
      <StyledGridItemRow>
        <StyledGridItemText>{priceBTC}</StyledGridItemText>
        <StyledGridItemSubText>{priceUSD}</StyledGridItemSubText>
      </StyledGridItemRow>
    </StyledGridItem>
  )
}

function Grid({ items }: { items: CollectionGridItem[] }) {
  return (
    <StyledGridContainer>
      <StyledGridHeader>
        <StyledGridTitle>Collection</StyledGridTitle>
      </StyledGridHeader>
      <StyledGridBody>
        {items.map(({ imageUrl, priceBTC, priceUSD, title }, i) => {
          const key = `item-${i}`

          return (
            <Item
              key={key}
              imageUrl={imageUrl}
              priceBTC={priceBTC}
              priceUSD={priceUSD}
              title={title}
            />
          )
        })}
      </StyledGridBody>
    </StyledGridContainer>
  )
}

export default Grid
