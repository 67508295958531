import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import * as React from "react"
import { Helmet } from "react-helmet"
import { RouterProvider } from "react-router-dom"
import router from "./router"

let theme = createTheme({
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold"
    },
    h2: {
      fontSize: "1.75rem",
      color: "darkgrey",
      fontWeight: "bolder"
    }
  }
})
theme = responsiveFontSizes(theme)

function App() {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.viral-loops.com/widgetsV2/core/loader.js"
        />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  )
}

export default App
