import { styled } from "@mui/material/styles"

export const StyledMoreCollectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTitle = styled("h2")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "14px",
  marginTop: "90px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "231px",
  width: "231px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledItemImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "231px",
  width: "231px",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledItemRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "6px",

  [theme.breakpoints.up("md")]: {
    marginTop: "12px"
  }
}))

export const StyledItemTitle = styled("div")(({ theme }) => ({
  color: "#000",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledItemPriceBTC = styled("div")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIMedium",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {}
}))
