import * as React from "react"
import {
  StyledFooter,
  StyledLogo,
  StyledText,
  StyledTextContainer,
  StyledRow
} from "./styles"

function Footer() {
  return (
    <StyledFooter>
      <StyledRow>
        <StyledLogo src="/thesis.svg" />
        <StyledTextContainer>
          <StyledText>A Thesis* Build.</StyledText>
          <StyledText>© 2018-2023 Thesis. Inc.</StyledText>
        </StyledTextContainer>
      </StyledRow>
    </StyledFooter>
  )
}

export default Footer
