import { styled } from "@mui/material/styles"

export const StyledItemContainer = styled("div")(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
}))

export const StyledItemImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  minHeight: "129px",
  width: "129px",

  [theme.breakpoints.up("md")]: {
    minHeight: "231px",
    width: "231px"
  }
}))

export const StyledItemRow = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "12px",
  width: "100%"
}))

export const StyledItemTitle = styled("h3")(({ theme }) => ({
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "12px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  textTransform: "uppercase",
  whiteSpace: "normal",
  width: "154px",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    width: "176px"
  }
}))

export const StyledItemPriceBTC = styled("h3")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "12px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))
