import { styled } from "@mui/material/styles"

export const StyledHeroContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 0,
  position: "relative",

  [theme.breakpoints.up("md")]: {
    marginBottom: "23px"
  }
}))

export const StyledTopTextContainer = styled("div")(({ theme }) => ({
  color: "#CCFFE7",
  display: "flex",
  fontSize: "24px",
  lineHeight: "110%",
  position: "absolute",
  left: 0,
  textTransform: "uppercase",
  top: "60px",
  width: "330px",
  zIndex: 2,

  [theme.breakpoints.up("md")]: {
    bottom: "30px",
    fontSize: "32px",
    left: "20px",
    top: "unset",
    width: "616px"
  }
}))

export const StyledHeroText = styled("div")(() => ({
  display: "flex",
  fontFamily: "ABCFavorit, sans-serif",
  fontSize: "24px",
  fontWeight: 500,
  whiteSpace: "pre-line",
  width: "100%"
}))

export const StyledBottomTextContainer = styled("div")(({ theme }) => ({
  bottom: "20px",
  color: "#CCFFE7",
  fontSize: "24px",
  lineHeight: "110%",
  justifyContent: "flex-end",
  position: "absolute",
  right: 0,
  textAlign: "right",
  textTransform: "uppercase",
  width: "330px",
  zIndex: 2,

  [theme.breakpoints.up("md")]: {
    bottom: "30px",
    fontSize: "32px",
    right: "20px",
    width: "430px"
  }
}))
