import * as React from "react"
import HorizontalGrid from "../../../../components/HorizontalGrid/HorizontalGrid"
import backgroundUrl from "../../../../utils/background-url"
import { MoreFromCollectionProps } from "../../types"
import {
  StyledMoreCollectionContainer,
  StyledItemContainer,
  StyledItemImage,
  StyledItemRow,
  StyledItemTitle,
  StyledItemPriceBTC,
  StyledTitle
} from "./styles"

function MoreCollection({ items }: { items: MoreFromCollectionProps[] }) {
  return (
    <StyledMoreCollectionContainer>
      <StyledTitle>More From Collection</StyledTitle>
      <HorizontalGrid
        gap={["20px", "25px"]}
        rowNum={[1, 1]}
        columnWidth={["231px", "231px"]}
      >
        {items.map(({ imageUrl, priceBTC, title }, i) => {
          const key = `more-collection-${i}`

          return (
            <StyledItemContainer key={key}>
              <StyledItemImage
                sx={{ backgroundImage: backgroundUrl(imageUrl) }}
              />
              <StyledItemRow>
                <StyledItemTitle>{title}</StyledItemTitle>
                <StyledItemPriceBTC>{priceBTC}</StyledItemPriceBTC>
              </StyledItemRow>
            </StyledItemContainer>
          )
        })}
      </HorizontalGrid>
    </StyledMoreCollectionContainer>
  )
}

export default MoreCollection
