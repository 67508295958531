import * as React from "react"
import { useLocation } from "react-router-dom"
import Footer from "../../components/FooterThesis/Footer"
import NavBar from "../../components/NavBar/NavBar"
import type { RouteOptions } from "../../router"
import { StyledLayout, StyledMain } from "./styles"

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

interface LayoutProps extends RouteOptions {
  children: React.ReactNode
  components: {
    footer?: boolean
    nav?: boolean
  }
  layout: "black" | "white"
}

function Layout(
  {
    children,
    components,
    fullLogo,
    layout,
    protected: _protected
  }: LayoutProps = {
    children: null,
    components: { footer: false, nav: true },
    fullLogo: false,
    layout: "black",
    protected: false
  }
) {
  const query = useQuery()

  /**
   * If the route is protected and the user has not granted access, return null.
   */
  if (
    _protected &&
    (!query.get("access") || query.get("access") !== "granted")
  ) {
    return null
  }

  return (
    <StyledLayout>
      {components.nav ? <NavBar fullLogo={fullLogo} layout={layout} /> : null}
      <StyledMain>{children}</StyledMain>
      {components.footer ? <Footer /> : null}
    </StyledLayout>
  )
}

export default Layout
