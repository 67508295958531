import { styled } from "@mui/material/styles"

export const StyledFooter = styled("div")(({ theme }) => ({
  backgroundColor: "black",
  borderTop: "1px solid #c775b2",
  display: "flex",
  flexDirection: "column",
  fontFamily: "ABCFavorit, sans-serif",
  padding: "40px 20px 40px",

  [theme.breakpoints.up("md")]: {
    padding: "20px 40px 60px"
  }
}))

export const StyledRow = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "55px",
  justifyContent: "flex-end",

  [theme.breakpoints.up("md")]: {
    height: "65px"
  }
}))

export const StyledText = styled("h3")(({ theme }) => ({
  color: "#fff",
  fontFamily: "NBIMedium",
  fontSize: "16px",
  fontWeight: 500,
  letterSpacing: "0.8px",
  lineHeight: "136%",

  [theme.breakpoints.up("md")]: {}
}))

export const StyledLogo = styled("img")(({ theme }) => ({
  display: "flex",
  height: "45px",
  marginRight: "24px",
  width: "35px",

  [theme.breakpoints.up("md")]: {
    height: "64px",
    width: "50px"
  }
}))
