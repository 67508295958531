import * as React from "react"
import Page from "../../components/Page/Page"
import HeroTopBottomText from "./components/Hero-TopBottomText/Hero-TopBottomText"
import RotateCollections from "./components/RotateCollections/RotateCollections"
import StackedCollections from "./components/StackedCollections/StackedCollections"
import SectionClubs from "./sections/Clubs/Clubs"
import SectionFreshlyMinted from "./sections/FreshlyMinted/FreshlyMinted"
import SectionJustLaunched from "./sections/JustLaunched/JustLaunched"
import SectionSelects from "./sections/Selects/Selects"
import SectionTop from "./sections/Top/Top"
import {
  HeroCollection,
  SectionClubsProps,
  SectionFreshlyMintedProps,
  SectionJustLaunchedProps,
  SectionSelectsProps,
  SectionTopProps
} from "./types"

interface HomepageProps {
  collections: HeroCollection[]
  collectionsMode: string
  sections: {
    clubs: SectionClubsProps
    freshlyMinted: SectionFreshlyMintedProps
    justLaunched: SectionJustLaunchedProps
    selects: SectionSelectsProps
    top: SectionTopProps
  }
}

function Homepage({ collections, collectionsMode, sections }: HomepageProps) {
  let Collections = null

  if (collectionsMode === "rotate") {
    Collections = <RotateCollections collections={collections} />
  } else {
    Collections = <StackedCollections collections={collections} />
  }

  return (
    <Page backgroundColor="#000">
      <HeroTopBottomText>{Collections}</HeroTopBottomText>

      <SectionSelects
        dropdown={sections.selects.dropdown}
        items={sections.selects.items}
        title={sections.selects.title}
      />
      <SectionTop
        dropdown={sections.top.dropdown}
        items={sections.top.items}
        title={sections.top.title}
      />
      <SectionClubs items={sections.clubs.items} title={sections.clubs.title} />
      <SectionJustLaunched
        items={sections.justLaunched.items}
        title={sections.justLaunched.title}
      />
      <SectionFreshlyMinted
        items={sections.freshlyMinted.items}
        title={sections.freshlyMinted.title}
      />
    </Page>
  )
}

export default Homepage
