import * as React from "react"
import HorizontalGrid from "../../../../components/HorizontalGrid/HorizontalGrid"
import backgroundUrl from "../../../../utils/background-url"
import Section from "../../components/Section/Section"
import { SectionSelectsProps, SectionSelectsItem } from "../../types"
import {
  StyledItemContainer,
  StyledItemImage,
  StyledItemPriceBTC,
  StyledItemPriceUSD,
  StyledItemRow,
  StyledItemTitle
} from "./styles"

export function Item({
  imageUrl,
  priceBTC,
  priceUSD,
  title
}: SectionSelectsItem) {
  return (
    <StyledItemContainer>
      <StyledItemImage sx={{ backgroundImage: backgroundUrl(imageUrl) }} />
      <StyledItemRow>
        <StyledItemTitle>{title}</StyledItemTitle>
      </StyledItemRow>
      <StyledItemRow>
        <StyledItemPriceBTC>{priceBTC}</StyledItemPriceBTC>
        <StyledItemPriceUSD>{priceUSD}</StyledItemPriceUSD>
      </StyledItemRow>
    </StyledItemContainer>
  )
}

function SectionSelects({ dropdown, items, title }: SectionSelectsProps) {
  return (
    <Section dropdown={dropdown} title={title}>
      <HorizontalGrid
        columnWidth={["197px", "329px"]}
        gap={["20px", "50px"]}
        rowNum={[1, 1]}
      >
        {items.map((props, i) => {
          const key = `selects-item-${i}`

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Item key={key} {...props} />
        })}
      </HorizontalGrid>
    </Section>
  )
}

export default SectionSelects
