import * as React from "react"
import { StyledContainer } from "./styles"

interface GridProps {
  children: React.ReactNode
  columnWidth: string[]
  gap: string[]
  rowHeight?: string[]
  rowNum: number[] // Number of rows
}

function Grid({ children, columnWidth, gap, rowHeight, rowNum }: GridProps) {
  const gridTemplateRows = `repeat(${rowNum[0]}, ${rowHeight?.[0]})`
  const gridTemplateRowsDesktop = `repeat(${rowNum[1]}, ${rowHeight?.[1]})`

  return (
    <StyledContainer
      sx={(theme) => ({
        gap: gap[0],
        gridAutoColumns: columnWidth[0],
        gridTemplateRows,

        [theme.breakpoints.up("md")]: {
          gap: gap[1],
          gridAutoColumns: columnWidth[1],
          gridTemplateRows: gridTemplateRowsDesktop
        }
      })}
    >
      {children}
    </StyledContainer>
  )
}

Grid.defaultProps = {
  rowHeight: ["1fr", "1fr"]
}

export default Grid
