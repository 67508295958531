import { styled } from "@mui/material/styles"

export const StyledItemContainer = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start"
}))

export const StyledItemNumber = styled("div")(() => ({
  alignItems: "center",
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "40px",
  fontWeight: 700,
  height: "37px",
  justifyContent: "center",
  marginRight: "15px",
  minWidth: "34px"
}))

export const StyledItemImage = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  marginRight: "15px",
  minHeight: "64px",
  minWidth: "64px",

  [theme.breakpoints.up("md")]: {
    minHeight: "64px",
    minWidth: "64px"
  }
}))

export const StyledItemRow = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
}))

export const StyledItemTitle = styled("h3")(({ theme }) => ({
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))

export const StyledItemPriceBTC = styled("h3")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))

export const StyledItemPriceUSD = styled("h3")(({ theme }) => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "6%",
  lineHeight: "100%",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  }
}))
