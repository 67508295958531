import { format } from "date-fns"
import * as React from "react"
import backgroundUrl from "../../../../utils/background-url"
import { OrdinalBodyProps, OrdinalTrait, OrdinalDetails } from "../../types"
import {
  StyledAccordianContainer,
  StyledAccordianIcon,
  StyledAccordianSection,
  StyledAccordianTitle,
  StyledAnchor,
  StyledBody,
  StyledBuyNowButton,
  StyledBuyNowIcon,
  StyledCollectionTitle,
  StyledDetailsSection,
  StyledFeaturedImage,
  StyledLabel,
  StyledOrdinalTitle,
  StyledOtherListingIcon,
  StyledOtherListingsSection,
  StyledPrice,
  StyledPriceSection,
  StyledRow,
  StyledSatBox,
  StyledSatRarity,
  StyledSubPrice,
  StyledTraitItem,
  StyledTraitLabel,
  StyledTraitList,
  StyledTraitRarity,
  StyledTraitValue,
  StyledTraitValueContainer
} from "./styles"

function rarityColor(rarity: string) {
  let color = "transparent"

  switch (rarity) {
    case "rare":
      color = "#ffb168"
      break
    case "super-rare":
      color = "#abff68"
      break
    case "uncommon":
      color = "#ff8bdb"
      break
    default:
      break
  }

  return color
}

function Rarity({ per, rarity }: { per: string; rarity: string }) {
  const bgColor = rarityColor(rarity)

  return (
    <StyledTraitRarity sx={{ backgroundColor: bgColor }}>
      {per}
    </StyledTraitRarity>
  )
}

function Accordian({
  min,
  title,
  traits
}: {
  min: number
  title: string
  traits: OrdinalTrait[]
}) {
  const [open, setOpen] = React.useState(false)

  const chevronDir = open ? "chevron-thin-up.svg" : "chevron-thin-down.svg"
  const chevronSrc = `/icons/${chevronDir}`
  const sxLastChild =
    open || (!open && min)
      ? {}
      : { borderBottom: "1px solid #666", paddingBottom: "20px" }

  const onToggleAccordian = () => setOpen(!open)

  return (
    <StyledAccordianContainer>
      <StyledRow sx={{ flexDirection: "row", justifyContent: "space-between" }}>
        <StyledAccordianTitle>{title}</StyledAccordianTitle>
        <StyledAccordianIcon
          src={chevronSrc}
          alt="arrow-down"
          onClick={onToggleAccordian}
        />
      </StyledRow>
      <StyledRow sx={sxLastChild}>
        <StyledTraitList>
          {traits.map(({ label, per, rarity, value }, i) => {
            const key = `trait-${i}`

            if (!open && i >= min) return null

            return (
              <StyledTraitItem key={key}>
                <StyledTraitLabel>{label}</StyledTraitLabel>
                <StyledTraitValueContainer sx={{ marginRight: "15px" }}>
                  <StyledTraitValue>{value}</StyledTraitValue>
                  <Rarity per={per} rarity={rarity} />
                </StyledTraitValueContainer>
              </StyledTraitItem>
            )
          })}
        </StyledTraitList>
      </StyledRow>
    </StyledAccordianContainer>
  )
}

function AccordianDetail({
  details,
  title
}: {
  details: OrdinalDetails
  title: string
}) {
  const [open, setOpen] = React.useState(false)

  const chevronDir = open ? "chevron-thin-up.svg" : "chevron-thin-down.svg"
  const chevronSrc = `/icons/${chevronDir}`
  const satBgColor = rarityColor(details.satRarity)
  const sxLastChild = open
    ? {}
    : { borderBottom: "1px solid #666", paddingBottom: "20px" }

  const onToggleAccordian = () => setOpen(!open)

  return (
    <StyledAccordianContainer>
      <StyledRow sx={{ flexDirection: "row", justifyContent: "space-between" }}>
        <StyledAccordianTitle>{title}</StyledAccordianTitle>
        <StyledAccordianIcon
          src={chevronSrc}
          alt="arrow-down"
          onClick={onToggleAccordian}
        />
      </StyledRow>
      <StyledRow sx={sxLastChild}>
        {open ? (
          <StyledTraitList>
            <StyledTraitItem>
              <StyledTraitLabel>Inscription Id</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.inscriptionId}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Inscription Number</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.inscriptionNumber}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Owner</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.owner}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>File Type</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.fileType}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Inscription Created</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>
                  {format(new Date(details.inscriptionCreatedAt), "PPPP ppp")}
                </StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Location</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.location}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Creation Transaction</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>
                  {details.creationTransaction}
                </StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>Creation Block</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.creationBlock}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>SAT Rarity</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>
                  <StyledSatRarity sx={{ backgroundColor: satBgColor }}>
                    {details.satRarity}
                  </StyledSatRarity>
                </StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
            <StyledTraitItem>
              <StyledTraitLabel>SAT</StyledTraitLabel>
              <StyledTraitValueContainer>
                <StyledTraitValue>{details.sat}</StyledTraitValue>
              </StyledTraitValueContainer>
            </StyledTraitItem>
          </StyledTraitList>
        ) : null}
      </StyledRow>
    </StyledAccordianContainer>
  )
}

function OtherListingIcon({
  height,
  href,
  img,
  width
}: {
  height: string
  href: string
  img: string
  width: string
}) {
  return (
    <StyledAnchor href={href}>
      <StyledOtherListingIcon
        sx={{
          backgroundImage: backgroundUrl(img),
          height,
          width
        }}
      />
    </StyledAnchor>
  )
}

function Body({ featuredImageUrl, ordinalInfo, traits }: OrdinalBodyProps) {
  return (
    <StyledBody>
      <StyledFeaturedImage
        sx={{ backgroundImage: backgroundUrl(featuredImageUrl) }}
      />
      <StyledDetailsSection>
        <StyledRow>
          <StyledCollectionTitle>
            {ordinalInfo.collectionTitle}
          </StyledCollectionTitle>
          <StyledOrdinalTitle>{ordinalInfo.ordinalTitle}</StyledOrdinalTitle>
        </StyledRow>
        <StyledPriceSection>
          <StyledLabel>Lowest Price</StyledLabel>
          <StyledRow>
            <StyledRow
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <StyledPrice>
                1.2 BTC <StyledSubPrice>/ Subprice</StyledSubPrice>
              </StyledPrice>
              <StyledSatBox>SAT #4</StyledSatBox>
            </StyledRow>
          </StyledRow>
          <StyledRow>
            <StyledBuyNowButton>
              Buy now on
              <StyledBuyNowIcon src="/media/ordinal/img/buy-now-icon.svg" />
            </StyledBuyNowButton>
          </StyledRow>
        </StyledPriceSection>
        <StyledOtherListingsSection>
          <StyledRow>
            <StyledLabel>Other Listings</StyledLabel>
          </StyledRow>
          <StyledRow>
            <OtherListingIcon
              href="#"
              img="/media/ordinal/img/other-listing-01.svg"
              height="20px"
              width="33px"
            />
            <OtherListingIcon
              href="#"
              img="/media/ordinal/img/other-listing-02.svg"
              height="26px"
              width="26px"
            />
            <OtherListingIcon
              href="#"
              img="/media/ordinal/img/other-listing-03.svg"
              height="20px"
              width="25px"
            />
          </StyledRow>
        </StyledOtherListingsSection>
        <StyledAccordianSection>
          <Accordian min={3} title="Top Traits" traits={traits.top} />
          <Accordian min={0} title="All Traits" traits={traits.all} />
          <AccordianDetail title="Details" details={traits.details} />
        </StyledAccordianSection>
      </StyledDetailsSection>
    </StyledBody>
  )
}

export default Body
