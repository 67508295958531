import { SxProps, Theme } from "@mui/material/styles"
import * as React from "react"
import Table from "../../../../components/Table/Table"
import backgroundUrl from "../../../../utils/background-url"
import useWindowDimensions from "../../../../utils/use-window-dimensions"
import { ColumnSuffix, StatProps, StatsProps } from "../../types"
import {
  StyledBTCIcon,
  StyledCellImage,
  StyledCellNumber,
  StyledCellValue,
  StyledTableSection
} from "./styles"

function StatsTable({
  columnsColored,
  columnsSuffix,
  columnsWidths,
  data,
  headings,
  tabs
}: StatsProps) {
  const { windowWidth } = useWindowDimensions()
  const isMobile = windowWidth < 768
  const widthIndex = isMobile ? 0 : 1

  const renderRow = (
    { title, floor, volume, change, imageUrl }: StatProps,
    i: number,
    sxRow: SxProps
  ) => {
    const values = [floor, volume, change]
    const cells = values.map((_value, j) => {
      const key = `table-cell-${j}`

      const width = columnsWidths[j][widthIndex]

      let textColor = "black"
      const value = _value.toString()

      if (columnsColored.includes(j)) {
        textColor = value.startsWith("-") ? "#ff4f4f" : "#29b450"
      }

      let ColumnPrefixComponent = ""

      switch (value.at(0)) {
        case "-":
          ColumnPrefixComponent = "" // Don't show double negative
          break
        case "+":
          ColumnPrefixComponent = "+"
          break

        default:
          if (textColor !== "black") {
            ColumnPrefixComponent = "+"
          }
      }

      let ColumnSuffixComponent: ColumnSuffix = ""

      if (columnsSuffix && columnsSuffix[j]) {
        switch (columnsSuffix[j]) {
          case "BTC":
            ColumnSuffixComponent = (
              <StyledBTCIcon
                sx={{
                  backgroundImage: backgroundUrl("/media/stats/img/btc.svg")
                }}
              />
            )
            break

          case "%":
            ColumnSuffixComponent = "%"
            break

          default:
            ColumnSuffixComponent = ""
        }
      }

      const sx = () => ({
        color: textColor,
        width
      })

      return (
        <Table.Cell key={key} sx={sx}>
          <StyledCellValue>
            {ColumnPrefixComponent}
            {value}
            {ColumnSuffixComponent}
          </StyledCellValue>
        </Table.Cell>
      )
    })

    const sx = (theme: Theme) => ({
      [theme.breakpoints.up("md")]: {
        flex: 1
      }
    })

    const num = i + 1

    cells.unshift(
      <Table.Cell key="table-cell-sticky" sx={sx}>
        <div>
          <Table.CellText>
            <StyledCellNumber>{num}</StyledCellNumber>
            {title}
          </Table.CellText>
        </div>
      </Table.Cell>
    )

    cells.push(
      <Table.Cell
        key="table-cell-image"
        sx={{ width: columnsWidths[columnsWidths.length - 1][widthIndex] }}
      >
        <StyledCellImage sx={{ backgroundImage: backgroundUrl(imageUrl) }} />
      </Table.Cell>
    )

    return <Table.Row sx={sxRow}>{cells}</Table.Row>
  }

  return (
    <StyledTableSection>
      <Table
        columnsWidths={columnsWidths}
        data={data}
        headings={headings}
        renderRow={renderRow}
        tabs={tabs}
      />
    </StyledTableSection>
  )
}

export default StatsTable
