import * as React from "react"
import { Helmet } from "react-helmet"

function BackgroundColor({ color }: { color: string }) {
  return (
    <Helmet>
      <style>{`body { background-color: ${color}; }`}</style>
    </Helmet>
  )
}

export default BackgroundColor
