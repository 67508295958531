import { styled } from "@mui/material/styles"

export const StyledSection = styled("div")(({ theme }) => ({
  backgroundColor: "#000",
  borderTop: "1px solid rgba(229, 229, 229, 0.5)",
  display: "flex",
  flexDirection: "column",
  margin: "0 -20px",
  padding: "56px 20px 50px",

  [theme.breakpoints.up("md")]: {
    margin: "0 -40px",
    padding: "100px 40px"
  }
}))

export const StyledTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 0 11px",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row"
  }
}))

export const StyledTitle = styled("h2")(({ theme }) => ({
  color: "#fff",
  display: "flex",
  fontFamily: "ABCFavorit",
  fontSize: "32px",
  fontWeight: 700,
  letterSpacing: "-0.03em",
  lineHeight: "100%",
  margin: 0,
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "80px"
  }
}))

export const StyledDropdownContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",

  [theme.breakpoints.up("md")]: {
    marginLeft: "15px",
    marginTop: "0px"
  }
}))

export const StyledIcon = styled("img")(({ theme }) => ({
  display: "flex",
  height: "10px",
  marginLeft: "8px",
  width: "20px",

  [theme.breakpoints.up("md")]: {
    height: "14px",
    width: "29px"
  }
}))
