import * as React from "react"
import hexToFilter from "../../../../utils/hex-to-filter"
import { SectionDropdownProps } from "../../types"
import { StyledDropdownContainer, StyledIcon, StyledTitle } from "./styles"

interface DropdownProps {
  dropdown: SectionDropdownProps
}

function Dropdown({ dropdown }: DropdownProps) {
  const filter = hexToFilter(dropdown.color)

  return (
    <StyledDropdownContainer>
      <StyledTitle sx={{ color: dropdown.color }}>
        {dropdown.items[0]}
      </StyledTitle>
      <StyledIcon
        src="/icons/chevron-down.svg"
        alt="arrow-down"
        sx={{ filter }}
      />
    </StyledDropdownContainer>
  )
}

export default Dropdown
