import { styled } from "@mui/material/styles"

export const StyledHero = styled("div")(({ theme }) => ({
  backgroundColor: "black",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "100vh",
  position: "relative",
  width: "530px",

  [theme.breakpoints.up("md")]: {
    height: "768px",
    paddingTop: "0",
    width: "100%"
  }
}))

export const StyledContent = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "flex-end",
  marginTop: "36px",
  padding: "20px",
  position: "relative",
  width: "100%",
  zIndex: 3,

  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "0",
    paddingBottom: "40px"
  }
}))

export const StyledItem01 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "166px",
  left: 0,
  position: "absolute",
  top: "500px",
  width: "295px",

  [theme.breakpoints.up("md")]: {
    height: "194px",
    left: "unset",
    marginTop: "280px",
    position: "relative",
    top: "unset",
    width: "345px"
  }
}))

export const StyledItem02 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "331px",
  left: 0,
  position: "absolute",
  top: "90px",
  width: "295px",

  [theme.breakpoints.up("md")]: {
    height: "480px",
    left: "unset",
    marginLeft: "28px",
    marginTop: "90px",
    position: "relative",
    top: "unset",
    width: "426px"
  }
}))

export const StyledItem03 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "159px",
  left: "315px",
  position: "absolute",
  top: "70px",
  width: "159px",

  [theme.breakpoints.up("md")]: {
    height: "231px",
    left: "unset",
    marginLeft: "28px",
    marginTop: "70px",
    position: "relative",
    top: "unset",
    width: "231px"
  }
}))

export const StyledItem04 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  height: "317px",
  left: "315px",
  position: "absolute",
  top: "310px",
  width: "190px",

  [theme.breakpoints.up("md")]: {
    height: "317px",
    left: "unset",
    marginLeft: "28px",
    marginTop: "230px",
    position: "relative",
    top: "unset",
    width: "190px"
  }
}))

export const StyledItemTextContainer = styled("div")(() => ({
  bottom: "-40px",
  display: "flex",
  flexDirection: "column",
  position: "absolute"
}))

export const StyledItemTitle = styled("div")(() => ({
  color: "#fff",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.06em",
  lineHeight: "120%",
  textTransform: "uppercase"
}))

export const StyledItemPrice = styled("div")(() => ({
  color: "#666",
  display: "flex",
  fontFamily: "NBIBold",
  fontSize: "14px",
  letterSpacing: "0.06em",
  lineHeight: "120%",
  textTransform: "uppercase"
}))

export const StyledSquares = styled("div")(({ theme }) => ({
  bottom: "100px",
  display: "none",
  flexDirection: "column",
  position: "absolute",
  right: "30px",

  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}))

export const StyledSquare = styled("div")(() => ({
  backgroundColor: "transparent",
  border: "1px solid #000",
  display: "flex",
  height: "21px",
  marginTop: "10px",
  width: "21px"
}))
