import { Theme } from "@mui/material/styles"
import * as React from "react"
import { CollectionStatsBoxProps } from "../../types"
import {
  StyledBox,
  StyledCol,
  StyledDetailBox,
  StyledRow,
  StyledStatLabel,
  StyledStatValue,
  StyledStatSubValue,
  StyledTitle
} from "./styles"

function StatsBox({ stats, title }: CollectionStatsBoxProps) {
  const colStyles = (theme: Theme) => ({
    marginLeft: "40px",
    width: "110px",

    [theme.breakpoints.up("md")]: {
      width: "180px"
    }
  })

  return (
    <StyledDetailBox
      sx={(theme) => ({
        marginTop: "40px",
        zIndex: 2,

        [theme.breakpoints.up("md")]: {
          left: "calc(50vw - 230px)",
          marginTop: "0",
          position: "absolute",
          top: "70px", // 40px from the hero + 70px = 100px from the top
          width: "561px"
        }
      })}
    >
      <StyledBox sx={{ alignItems: "flex-start" }}>
        <StyledTitle>{title}</StyledTitle>
      </StyledBox>
      <StyledBox sx={{ borderBottom: "1px solid #000" }}>
        <StyledRow>
          <StyledCol>
            <StyledStatLabel>Floor Price</StyledStatLabel>
            <StyledStatValue>
              {stats.floorPrice.value}{" "}
              <StyledStatSubValue>
                {stats.floorPrice.subValue}
              </StyledStatSubValue>
            </StyledStatValue>
          </StyledCol>
          <StyledCol sx={colStyles}>
            <StyledStatLabel>Items</StyledStatLabel>
            <StyledStatValue>{stats.items.value}</StyledStatValue>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <StyledStatLabel>Volume</StyledStatLabel>
            <StyledStatValue>
              {stats.volume.value}{" "}
              <StyledStatSubValue>{stats.volume.subValue}</StyledStatSubValue>
            </StyledStatValue>
          </StyledCol>
          <StyledCol sx={colStyles}>
            <StyledStatLabel>Listed</StyledStatLabel>
            <StyledStatValue>{stats.listed.value}</StyledStatValue>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <StyledStatLabel>Unique Owners</StyledStatLabel>
            <StyledStatValue>Value</StyledStatValue>
          </StyledCol>
          <StyledCol sx={colStyles}>
            <StyledStatLabel>Sats</StyledStatLabel>
            <StyledStatValue>{stats.sats.value}</StyledStatValue>
          </StyledCol>
        </StyledRow>
      </StyledBox>
    </StyledDetailBox>
  )
}

export default StatsBox
