import * as React from "react"
import Page from "../../components/Page/Page"
import Body from "./components/Body/Body"
import MoreCollection from "./components/MoreCollection/MoreCollection"
import { OrdinalProps } from "./types"

function OrdinalPage({
  featuredImageUrl,
  moreFrom,
  ordinalInfo,
  traits
}: OrdinalProps) {
  return (
    <Page>
      <Body
        featuredImageUrl={featuredImageUrl}
        ordinalInfo={ordinalInfo}
        traits={traits}
      />
      <MoreCollection items={moreFrom} />
    </Page>
  )
}

export default OrdinalPage
