const isSafari = (): boolean =>
  // @ts-expect-error The browser tests HTMLElement which is different per browser
  /constructor/i.test(window.HTMLElement) ||
  (function toString(p) {
    return p.toString() === "[object SafariRemoteNotification]"
  })(
    // @ts-expect-error This is a Safari-only property
    !window.safari ||
      // @ts-expect-error This is a Safari-only property
      (typeof safari !== "undefined" && window.safari.pushNotification)
  )

export default isSafari
