import { styled } from "@mui/material/styles"

export const StyledLayout = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  zIndex: 1
}))

export const StyledMain = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: "0 20px",
  position: "relative",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    padding: "0 40px"
  }
}))
