import AccountCircle from "@mui/icons-material/AccountCircle"
import MailIcon from "@mui/icons-material/Mail"
import MoreIcon from "@mui/icons-material/MoreVert"
import NotificationsIcon from "@mui/icons-material/Notifications"
import SearchIcon from "@mui/icons-material/Search"
import { SxProps } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Toolbar from "@mui/material/Toolbar"
import * as React from "react"
import hexToFilter from "../../utils/hex-to-filter"
import {
  StyledHamburger,
  StyledLogo,
  StyledLink,
  StyledLinks,
  StyledSearchWrapper,
  StyledHeroGradientTop
} from "./styles"

function NavBar({
  fullLogo = false,
  layout
}: {
  fullLogo?: boolean
  layout: "black" | "white"
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const logoSrc = fullLogo ? "/etcher-pink.svg" : "/e.svg"
  const logoSx: SxProps = fullLogo
    ? {
        height: "24px",
        width: "98px"
      }
    : {}

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = "primary-search-account-menu"
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  )

  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <Box
      sx={() => ({
        flexGrow: 1,
        position: "static"
      })}
    >
      <StyledHeroGradientTop />
      <AppBar
        sx={(theme) => ({
          background: "none",
          boxShadow: "none",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px",

          [theme.breakpoints.up("md")]: {
            padding: "20px 40px"
          }
        })}
      >
        <StyledLogo
          src={logoSrc}
          sx={{
            filter: hexToFilter(layout === "black" ? "#fbccff" : "#000"),
            ...logoSx
          }}
        />
        <Toolbar
          sx={{
            minHeight: "unset !important", // Not a fan of !important but needed here
            justifyContent: "flex-end",
            padding: 0,
            "& button": { marginRight: 0 },
            "& path": {
              color: "black"
            }
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <StyledHamburger src="/hamburger.svg" />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <StyledSearchWrapper>
              <SearchIcon />
            </StyledSearchWrapper>
            <StyledLinks>
              <StyledLink
                href="#"
                sx={{ color: layout === "white" ? "#000" : "#fff" }}
              >
                Stats
              </StyledLink>
              <StyledLink
                href="#"
                sx={{ color: layout === "white" ? "#000" : "#fff" }}
              >
                Browse
              </StyledLink>
              <StyledLink
                href="#"
                sx={{ color: layout === "white" ? "#000" : "#fff" }}
              >
                Create
              </StyledLink>
            </StyledLinks>
          </Box>
          <Box sx={{ display: { xs: "none", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  )
}

NavBar.defaultProps = {
  fullLogo: false
}

export default NavBar
