import * as React from "react"
import Page from "../../components/Page/Page"
import Grid from "./components/Grid/Grid"
import Hero from "./components/Hero/Hero"
import { CollectionDetailsProps } from "./types"

function CollectionPage({
  heroImages,
  items,
  links,
  stats,
  summary,
  title
}: CollectionDetailsProps) {
  return (
    <Page>
      <Hero
        heroImages={heroImages}
        links={links}
        stats={stats}
        summary={summary}
        title={title}
      />
      <Grid items={items} />
    </Page>
  )
}

export default CollectionPage
