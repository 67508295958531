import { styled } from "@mui/material/styles"

export const StyledHero = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "40px 0 20px",
  position: "relative",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    height: "900px"
  }
}))

export const StyledImage01 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "205px",
  left: "-22px",
  position: "absolute",
  top: "80px",
  width: "205px",

  [theme.breakpoints.up("md")]: {
    height: "410px",
    left: "258px",
    top: "58px",
    width: "410px"
  }
}))

export const StyledImage02 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "205px",
  left: "-131px",
  position: "absolute",
  top: "295px",
  width: "295px",

  [theme.breakpoints.up("md")]: {
    height: "410px",
    left: "40px",
    top: "489px",
    width: "529px"
  }
}))

export const StyledImage03 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "205px",
  left: "195px",
  position: "absolute",
  top: "61px",
  width: "295px",

  [theme.breakpoints.up("md")]: {
    height: "410px",
    left: "694px",
    top: "21px",
    width: "529px"
  }
}))

export const StyledImage04 = styled("div")(({ theme }) => ({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "205px",
  left: "306px",
  position: "absolute",
  top: "279px",
  width: "295px",

  [theme.breakpoints.up("md")]: {
    height: "410px",
    left: "915px",
    top: "457px",
    width: "529px"
  }
}))
