import * as React from "react"
import backgroundUrl from "../../../../utils/background-url"
import { CollectionHeroProps } from "../../types"
import StatsBox from "../DetailBox/StatsBox"
import SummaryBox from "../DetailBox/SummaryBox"
import {
  StyledHero,
  StyledImage01,
  StyledImage02,
  StyledImage03,
  StyledImage04
} from "./styles"

function Hero({
  heroImages,
  links,
  stats,
  summary,
  title
}: CollectionHeroProps) {
  return (
    <StyledHero>
      <StatsBox stats={stats} title={title} />
      <SummaryBox links={links} summary={summary} />

      <StyledImage01 sx={{ backgroundImage: backgroundUrl(heroImages[0]) }} />
      <StyledImage02 sx={{ backgroundImage: backgroundUrl(heroImages[1]) }} />
      <StyledImage03 sx={{ backgroundImage: backgroundUrl(heroImages[2]) }} />
      <StyledImage04 sx={{ backgroundImage: backgroundUrl(heroImages[3]) }} />
    </StyledHero>
  )
}

export default Hero
