import * as React from "react"
import {
  StyledBottomTextContainer,
  StyledHeroContainer,
  StyledHeroText,
  StyledTopTextContainer
} from "./styles"

interface HeroTopBottomTextProps {
  children: React.ReactNode
}

/**
 * This component is needed to freeze the text in place while the collections
 * scroll horizontally.
 */
function HeroTopBottomText({ children }: HeroTopBottomTextProps) {
  return (
    <StyledHeroContainer>
      <StyledTopTextContainer>
        <StyledHeroText>All the best ordinals. Every platform.</StyledHeroText>
      </StyledTopTextContainer>
      <StyledBottomTextContainer>
        <StyledHeroText>
          Aggregated & curated. So you dont have to.
        </StyledHeroText>
      </StyledBottomTextContainer>
      {children}
    </StyledHeroContainer>
  )
}

export default HeroTopBottomText
