import { styled } from "@mui/material/styles"

export const StyledTableContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "scroll",
  position: "relative",
  width: "100%",
  zIndex: 1,

  "&::-webkit-scrollbar": {
    display: "none"
  }
}))

export const StyledTableTabsContainer = styled("div")(() => ({
  display: "flex",
  left: 0,
  position: "sticky",

  "&::-webkit-scrollbar": {
    display: "none"
  }
}))

export const StyledTableTabs = styled("div")(() => ({
  display: "flex"
}))

export const StyledTabHeader = styled("span")(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.5)",
  cursor: "pointer",
  display: "flex",
  fontSize: "24px",
  height: "34px",
  marginRight: "20px",
  textTransform: "uppercase",

  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
    height: "54px",
    marginRight: "35px"
  }
}))

export const StyledTable = styled("div")(() => ({
  display: "flex",
  flexDirection: "column"
}))

export const StyledTableHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "20px",
  marginBottom: "20px",

  [theme.breakpoints.up("md")]: {
    marginTop: "26px"
  }
}))

export const StyledTableHeading = styled("th")(({ theme }) => ({
  display: "flex",
  fontSize: "16px",
  fontWeight: 400,
  justifyContent: "flex-end",
  textTransform: "uppercase",
  width: "200px",

  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  }
}))

export const StyledTableCell = styled("td")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontSize: "20px",
  letterSpacing: "1.6px",
  justifyContent: "flex-end",
  textTransform: "uppercase",
  width: "200px",

  [theme.breakpoints.up("md")]: {
    fontSize: "24px"
  },

  "& a": {
    alignItems: "center",
    display: "flex",
    fontSize: "32px",
    height: "100%",
    justifyContent: "flex-end",
    width: "100%"
  }
}))

export const StyledTableRow = styled("tr")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("md")]: {
    width: "100%"
  }
}))

export const StyledTableBodyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "fit-content",

  [theme.breakpoints.up("md")]: {
    width: "100%"
  }
}))

export const StyledTableCellText = styled("span")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%"
}))
