import * as React from "react"
import browser from "../../utils/browser-info"
import isSafari from "../../utils/is-safari"
import { StyledVideo } from "./styles"

interface VideoProps {
  autoPlay?: boolean
  children: React.ReactNode
  objectFitFill?: boolean
  randomDelay?: boolean // Randomly delay the start of the video by X milliseconds (try to fix some browsers).
  customStyles?: Record<string, string | number>
}

interface VideoState {
  browserIsSafari: boolean
  canPlay: boolean
  objectFillOverride: boolean
  safariDisplayReady: boolean
  safariVersion: number
}

/**
 * NOTE: This is needed because of fun browsers & devices.
 * @see: https://github.com/facebook/react/issues/10389#issuecomment-450249334
 */
class VideoComponent extends React.Component<VideoProps, VideoState> {
  // @ts-expect-error It's ok that it's not initialized in the constructor.
  objectFillTimeout: ReturnType<typeof setTimeout>

  randomDelayTime = 0 // In milliseconds

  ref = React.createRef<HTMLVideoElement>()

  // @ts-expect-error It's ok that it's not initialized in the constructor.
  safariDelayTimeout: ReturnType<typeof setTimeout>

  // @ts-expect-error It's ok that it's not initialized in the constructor.
  timeout: ReturnType<typeof setTimeout>

  constructor(props: VideoProps) {
    super(props)

    const { randomDelay } = props

    this.state = {
      browserIsSafari: false,
      canPlay: !randomDelay,
      // eslint-disable-next-line react/no-unused-state
      objectFillOverride: false,
      safariDisplayReady: false, // In certain scenarios we delay displaying to Safari to prevent flicker/movement of video
      safariVersion: 0
    }
  }

  componentDidMount() {
    const ref = this.ref.current

    // Need the following attributes to make iOS and Android devices happy with autoplay videos.
    ref?.setAttribute("playsinline", "true")
    ref?.setAttribute("defaultMuted", "true")

    this.timeout = setTimeout(() => {
      this.setState({ canPlay: true })
    }, this.randomDelayTime)

    // Safari is a special beast :O
    if (isSafari()) {
      const browserVersion = parseInt(browser.version, 2)
      const { objectFitFill } = this.props

      if (objectFitFill) {
        this.objectFillTimeout = setTimeout(() => {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ objectFillOverride: true })
        }, 0)
      }

      this.setState({
        browserIsSafari: true,
        safariVersion: browserVersion
      })
    }
  }

  /**
   *
   * NOTE: You can use `UNSAFE` here but then you gotta take React out of Strict mode. Just better
   * to let the console warning stay.
   */
  componentDidUpdate() {
    const ref = this.ref.current
    const { browserIsSafari, canPlay } = this.state

    if (canPlay && this.isAutoPlay()) {
      ref?.play()
    } else {
      ref?.pause()
      // @ts-expect-error Just assume it's there.
      ref.currentTime = 0 // Restart video from beginning on play
    }

    if (browserIsSafari && typeof this.safariDelayTimeout === "undefined") {
      this.safariDelayTimeout = setTimeout(() => {
        this.setState({ safariDisplayReady: true })
      }, 250)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.objectFillTimeout)
    clearTimeout(this.safariDelayTimeout)
    clearTimeout(this.timeout)
  }

  isAutoPlay() {
    // eslint-disable-next-line react/destructuring-assignment
    const autoPlay = Boolean(this.props.autoPlay)

    return autoPlay
  }

  render() {
    const { browserIsSafari, safariDisplayReady, safariVersion } = this.state
    const { children, customStyles, objectFitFill } = this.props

    const videoCSS = {
      opacity: 1
    }

    const style: Record<string, string> = {}

    // Safari will only accept this on `style`, not `css`.
    if (browserIsSafari) {
      if (safariVersion >= 16) {
        style.objectFit = "contain"
      } else {
        style.objectFit = "fill"
      }
    }

    // Funky stuff to help Safari
    if (objectFitFill) {
      if (browserIsSafari) {
        videoCSS.opacity = 0

        // Delay showing to prevent the snapping from weird positions
        if (safariDisplayReady) {
          videoCSS.opacity = 1
        }
      }
    }

    return (
      <StyledVideo
        autoPlay={this.isAutoPlay()}
        loop
        muted
        preload="metadata"
        ref={this.ref}
        style={{ ...style, ...(customStyles ?? {}) }}
        sx={videoCSS}
      >
        {children}
      </StyledVideo>
    )
  }
}

// @ts-expect-error The default props is there.
VideoComponent.defaultProps = {
  autoPlay: false,
  customStyles: {},
  objectFitFill: false,
  randomDelay: false
}

export default VideoComponent
