// NOTE: Temporary data until we have a CMS / API
const data = {
  collection: {
    components: {
      footer: false,
      nav: true
    },
    layout: "white",
    heroImages: [
      "/media/collection/img/hero-01.png",
      "/media/collection/img/hero-02.png",
      "/media/collection/img/hero-03.png",
      "/media/collection/img/hero-04.png"
    ],
    items: [
      {
        imageUrl: "/media/collection/img/505-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #505"
      },
      {
        imageUrl: "/media/collection/img/2929-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #2929"
      },
      {
        imageUrl: "/media/collection/img/3646-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #3646"
      },
      {
        imageUrl: "/media/collection/img/4165-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #4165"
      },
      {
        imageUrl: "/media/collection/img/6493-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #6493"
      },
      {
        imageUrl: "/media/collection/img/7277-dead.png",
        priceBTC: "1.1 BTC",
        priceUSD: "$30,245",
        title: "DeGods #7277"
      }
    ],
    links: {
      website: "https://twitter.com/degodsnft",
      discord: "https://discord.gg/etcher",
      instagram: "https://www.instagram.com/yugalabs/",
      twitter: "https://twitter.com/degodsnft"
    },
    stats: {
      floorPrice: {
        value: "1.1 BTC",
        subValue: "$30,245"
      },
      items: {
        value: "8,239"
      },
      volume: {
        value: "283.7 BTC",
        subValue: "$7.19M"
      },
      listed: {
        value: "304"
      },
      owners: {
        value: "17%"
      },
      sats: {
        value: "210.1k-229.6k"
      }
    },
    summary:
      "DeGods is a digital art collection and global community of creators, developers, entrepreneurs, athletes, artists, experimenters and innovators.",
    title: "BTC DeGods"
  },
  homepage: {
    components: {
      footer: false,
      nav: true
    },
    layout: "black",
    collections: [
      {
        item01: {
          imageUrl: "/media/homepage/img/collection-01.png",
          price: "0.76 BTC",
          title: "Nakamoto"
        },
        item02: {
          imageUrl: "/media/homepage/img/collection-02.png",
          price: "0.76 BTC",
          title: "Striations"
        },
        item03: {
          imageUrl: "/media/homepage/img/collection-03.png",
          price: "0.76 BTC",
          title: "SZN1"
        },
        item04: {
          imageUrl: "/media/homepage/img/collection-04.png",
          price: "0.76 BTC",
          title: "The Forest"
        }
      },
      {
        item01: {
          imageUrl: "/media/homepage/img/collection-01.png",
          price: "0.76 BTC",
          title: "Nakamoto"
        },
        item02: {
          imageUrl: "/media/homepage/img/collection-02.png",
          price: "0.76 BTC",
          title: "Striations"
        },
        item03: {
          imageUrl: "/media/homepage/img/collection-03.png",
          price: "0.76 BTC",
          title: "SZN1"
        },
        item04: {
          imageUrl: "/media/homepage/img/collection-04.png",
          price: "0.76 BTC",
          title: "The Forest"
        }
      }
    ],
    collectionsMode: "rotate", // 'rotate' | 'stacked',
    sections: {
      selects: {
        dropdown: {
          color: "#FBCCFF",
          items: ["5.24"]
        },
        items: [
          {
            imageUrl: "/media/homepage/img/ordinal-punks.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "Ordinal Punks"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-toadz.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "Bitcoin Toadz"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-bandits.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "Bitcoin Bandits"
          },
          {
            imageUrl: "/media/homepage/img/recursive-frogs.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "Recursive Frogs"
          }
        ],
        title: "Etcher Selects"
      },
      top: {
        dropdown: {
          color: "#e2ffcc",
          items: ["This week"]
        },
        items: [
          {
            imageUrl: "/media/homepage/img/abstra-ordinals.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "AbstraOrdinals"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-bandits.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Bitcoin Bandits"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-toadz.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Bitcoin Toadz"
          },
          {
            imageUrl: "/media/homepage/img/inscribed_pepes.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Inscribed Pepes"
          },
          {
            imageUrl: "/media/homepage/img/ordinal-apes.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Ordinal Apes"
          },
          {
            imageUrl: "/media/homepage/img/ordinal-maxi-business.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Ordinal Maxi Business"
          },
          {
            imageUrl: "/media/homepage/img/ordinal-punks.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Ordinal Punks"
          },
          {
            imageUrl: "/media/homepage/img/pizza-artifacts.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Pizza Artifacts"
          },
          {
            imageUrl: "/media/homepage/img/recursive-frogs.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Recursive Frogs"
          },
          {
            imageUrl: "/media/homepage/img/recursive-punks.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Recursive Punks"
          },
          {
            imageUrl: "/media/homepage/img/uncommon-patterns.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "Uncommon Patterns"
          },
          {
            imageUrl: "/media/homepage/img/btc-degods.png",
            collectionBTC: "100.1 BTC",
            priceBTC: "1.1 BTC",
            title: "BTC DeGods"
          }
        ],
        title: "Top"
      },
      clubs: {
        items: [
          {
            imageUrl: "/media/homepage/img/clubs-01.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "<1K Inscriptions"
          },
          {
            imageUrl: "/media/homepage/img/clubs-01.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "<1K Inscriptions"
          },
          {
            imageUrl: "/media/homepage/img/clubs-01.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "<1K Inscriptions"
          },
          {
            imageUrl: "/media/homepage/img/clubs-01.png",
            priceBTC: "1.1 BTC",
            priceUSD: "$30,245",
            title: "<1K Inscriptions"
          }
        ],
        title: "Inscription Clubs"
      },
      justLaunched: {
        items: [
          {
            imageUrl: "/media/homepage/img/pizza-artifacts.png",
            priceBTC: "1.1 BTC",
            title: "Pizza Artifacts"
          },
          {
            imageUrl: "/media/homepage/img/uncommon-patterns.png",
            priceBTC: "1.1 BTC",
            title: "Uncommon Patterns"
          },
          {
            imageUrl: "/media/homepage/img/inscribed_pepes.png",
            priceBTC: "1.1 BTC",
            title: "Inscribed Pepes"
          },
          {
            imageUrl: "/media/homepage/img/recursive-punks.png",
            priceBTC: "1.1 BTC",
            title: "Recursive Punks"
          },
          {
            imageUrl: "/media/homepage/img/ordinal-apes.png",
            priceBTC: "1.1 BTC",
            title: "Ordinal Apes"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-bear-cubs.png",
            priceBTC: "1.1 BTC",
            title: "Bitcoin Bear Cubs"
          },
          {
            imageUrl: "/media/homepage/img/ordidogz.png",
            priceBTC: "1.1 BTC",
            title: "Ordidogs"
          },
          {
            imageUrl: "/media/homepage/img/atomic-ordinals.jpeg",
            priceBTC: "1.1 BTC",
            title: "Atomic Ordinals"
          }
        ],
        title: "Just Launched"
      },
      freshlyMinted: {
        items: [
          {
            imageUrl: "/media/homepage/img/pizza-artifacts.png",
            priceBTC: "1.1 BTC",
            title: "Pizza Artifacts #867"
          },
          {
            imageUrl: "/media/homepage/img/uncommon-patterns.png",
            priceBTC: "1.1 BTC",
            title: "Uncommon Patterns #5309"
          },
          {
            imageUrl: "/media/homepage/img/inscribed_pepes.png",
            priceBTC: "1.1 BTC",
            title: "Inscribed Pepes #4"
          },
          {
            imageUrl: "/media/homepage/img/recursive-punks.png",
            priceBTC: "1.1 BTC",
            title: "Recursive Punks #8"
          },
          {
            imageUrl: "/media/homepage/img/ordinal-apes.png",
            priceBTC: "1.1 BTC",
            title: "Ordinal Apes #15"
          },
          {
            imageUrl: "/media/homepage/img/bitcoin-bear-cubs.png",
            priceBTC: "1.1 BTC",
            title: "Bitcoin Bear Cubs #16"
          },
          {
            imageUrl: "/media/homepage/img/ordidogz.png",
            priceBTC: "1.1 BTC",
            title: "Ordidogs #23"
          },
          {
            imageUrl: "/media/homepage/img/atomic-ordinals.jpeg",
            priceBTC: "1.1 BTC",
            title: "Atomic Ordinals #42"
          }
        ],
        title: "Freshly Minted"
      }
    },
    stats: {
      top: [],
      trending: []
    }
  },
  mystery: {
    components: {
      footer: true,
      nav: false
    },
    layout: "black"
  },
  ordinal: {
    components: {
      footer: false,
      nav: true
    },
    layout: "white",
    featuredImageUrl: "/media/ordinal/img/featured.png",
    ordinalInfo: {
      collectionTitle: "Degods",
      ordinalTitle: "Degod #4530"
    },
    traits: {
      top: [
        {
          label: "head",
          per: "1%",
          rarity: "super-rare",
          value: "mythic helmet"
        },
        {
          label: "skin",
          per: "13%",
          rarity: "rare",
          value: "transcendent"
        },
        {
          label: "eyes",
          per: "29%",
          rarity: "uncommon",
          value: "night vision"
        }
      ],
      all: [
        {
          label: "head",
          per: "1%",
          rarity: "super-rare",
          value: "mythic helmet"
        },
        {
          label: "shoulders",
          per: "50%",
          rarity: "uncommon",
          value: "padding"
        },
        {
          label: "knees",
          per: "60%",
          rarity: "uncommon",
          value: "uncommon arrow"
        },
        {
          label: "toes",
          per: "70%",
          rarity: "uncommon",
          value: "black boots"
        },
        {
          label: "skin",
          per: "13%",
          rarity: "rare",
          value: "transcendent"
        },
        {
          label: "eyes",
          per: "29%",
          rarity: "uncommon",
          value: "night vision"
        }
      ],
      details: {
        inscriptionId: "0x1234567890",
        inscriptionNumber: "1234567890",
        owner: "0x1234567890",
        fileType: "webp",
        inscriptionCreatedAt: "2021-08-01T00:00:00.000Z",
        location: "0x1234567890",
        creationTransaction: "0x1234567890",
        creationBlock: "1234567890",
        satRarity: "rare",
        sat: "294,624"
      }
    },
    moreFrom: [
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      },
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      },
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      },
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      },
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      },
      {
        imageUrl: "/media/ordinal/img/more-from-01.png",
        priceBTC: "1.1 BTC",
        title: "Degod #4530"
      }
    ]
  },
  stats: {
    components: {
      footer: false,
      nav: true
    },
    layout: "white",
    tabs: ["top", "trending", "watch"],
    headings: ["collection", "floor", "volume", "% change", ""], // Last heading is empty for the image column
    columnsColored: [2], // Which columns to color red/green (skips the first)
    columnsSuffix: ["BTC", "BTC", "%"], // What is the suffix for each column that has a value (skips the first)
    // The width of each column (skips the first). For each column, the first value is mobile width, the second is desktop width
    columnsWidths: [
      ["120px", "140px"],
      ["160px", "180px"],
      ["160px", "180px"],
      ["120px", "120px"]
    ],
    data: [
      // Top
      [
        {
          title: "Twelvefold",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        },
        {
          title: "Thirteenfold",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "-199"
        },
        {
          title: "Ninefold",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        }
      ],
      // Trending
      [
        {
          title: "Trendable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        },
        {
          title: "Trendable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        },
        {
          title: "Trendable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        }
      ],
      // Watch
      [
        {
          title: "Watchable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        },
        {
          title: "Watchable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        },
        {
          title: "Watchable",
          imageUrl: "/media/stats/img/twelvefold.png",
          floor: "2.1",
          volume: "159.3",
          change: "199"
        }
      ]
    ]
  }
}

export default data
