import * as React from "react"
import HorizontalGrid from "../../../../components/HorizontalGrid/HorizontalGrid"
import backgroundUrl from "../../../../utils/background-url"
import Section from "../../components/Section/Section"
import { SectionTopProps, SectionTopItem } from "../../types"
import {
  StyledItemContainer,
  StyledItemImage,
  StyledItemNumber,
  StyledItemPriceBTC,
  StyledItemRow,
  StyledItemTitle
} from "./styles"

export function Item({
  collectionBTC,
  imageUrl,
  index,
  priceBTC,
  title
}: SectionTopItem) {
  return (
    <StyledItemContainer>
      <StyledItemNumber>{index}</StyledItemNumber>
      <StyledItemImage sx={{ backgroundImage: backgroundUrl(imageUrl) }} />
      <StyledItemRow sx={{ flexDirection: "column" }}>
        <StyledItemRow>
          <StyledItemTitle>{title}</StyledItemTitle>
        </StyledItemRow>
        <StyledItemRow sx={{ justifyContent: "flex-start", marginTop: "8px" }}>
          <StyledItemPriceBTC>
            {priceBTC} &#47; {collectionBTC}
          </StyledItemPriceBTC>
        </StyledItemRow>
      </StyledItemRow>
    </StyledItemContainer>
  )
}

function SectionTop({ dropdown, items, title }: SectionTopProps) {
  return (
    <Section dropdown={dropdown} title={title}>
      <HorizontalGrid
        columnWidth={["250px", "419px"]}
        gap={["20px", "50px"]}
        rowNum={[5, 3]}
      >
        {items.map((props, i) => {
          const key = `selects-item-${i}`

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Item key={key} {...props} index={i + 1} />
        })}
      </HorizontalGrid>
    </Section>
  )
}

export default SectionTop
